// slack plugin customize
// http://kenwheeler.github.io/slick/
.slick-slide {
  img {
    width: 100%; } }
.slick-arrow {
  position: absolute;
  top: 50%;
  text-indent: 999px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin-top: -35px;
  background: transparent;
  border: 0;
  margin-top: -25px;
  border-radius: 50px;
  z-index: 561;
  &::after {
    content: '';
    display: block;
    border-left: 3px solid #FFF;
    border-bottom: 3px solid #FFF;
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 14px;
    left: 18px; }
  &:hover {
    outline: 0;
    background: #ffffff;
    &::after {
      border-color: #000000; } }
  &:focus {
    outline: 0; }
  &.slick-disabled {
    opacity: 0;
    visibility: hidden; }
  &.slick-prev {
    left: 10px; }
  &.slick-next {
    right: 10px;
    &::after {
      right: 20px;
      left: auto;
      transform: rotate(225deg); } } }
.slick-dots {
  margin: 0;
  text-align: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
  > li {
    display: inline-block;
    &.slick-active {
      > button {
        background: $primary;
        width: 60px; } }
    > button {
      width: 15px;
      height: 4px;
      background: transparent;
      border: 0;
      border-radius: 10px;
      padding: 0;
      margin: 0 3px;
      text-indent: -9999px;
      overflow: hidden;
      transition: width .2s ease-in-out;
      &:hover,&:focus,&:active,&.active {
        outline: 0 !important; } } } }

*[class^='svg-empty'] {
  fill: $gray-300 !important; }
*[class^='svg-active'] {
  fill: $yellow !important; }
*[class^='svg-hovered'] {
  fill: $orange !important; }

