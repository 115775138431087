// 需要載入的
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;700&display=swap');
@import "functions";
@import "helpers/variables";
@import "helpers/mixin";
@import "helpers/extend";
@import "bootstrap";

// 各個頁面
@import "pages/plugins.sass";
@import "pages/layout.sass";
@import "pages/index.sass";
@import "pages/videos.sass";
@import "pages/course.sass";
@import "pages/about.sass";

// 區塊式內容
@import "components/navigation";
@import "components/section";
@import "components/item-card";
@import "components/carts";

// 元件
// @import "components/form-square";
// @import "components/process-steps";
// @import "components/interactive"; // 互動元件
@import "components/custom-bs4.sass"; // Bootstrap 4 調整

// 通用類別
@import "components/utilities";
