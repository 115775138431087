.hero-slider-container {
  padding: 0; }
.hero-slider {
  padding: 1rem 0 4rem;
  overflow: hidden;
  width: 100%;
  @include md-up {
    padding: 2rem 0 4rem; }
  .slick-arrow {
    &.slick-prev {
      @include md-up {
        left: calc(50vw + 50px); } }
    &.slick-arrow.slick-next {
      @include md-up {
        right: calc(50vw + 50px); } } }
  .slick-dots {
    bottom: -50px;
    > li {
      &.slick-active {
        > button {
          background: $primary;
          opacity: 1; } }
      > button {
        background: $white;
        opacity: .6; } } } }
.hero-banner {
  @include md-up {
    margin-left: -50vw;
    margin-right: -50vw; }
  .slick-slide {
    padding: 0 20px;
    @include md-down {
      padding: 0 8px; }
    img {
      border-radius: 12px;
      box-shadow: 0 5px 25px rgba(0,0,0,.15); }
    a {
      &:focus {
        outline: 0; } } } }

.header-panel {
  background: $white;
  color: $black;
  box-shadow: 50px 50px 100px rgba(0,0,0,.15);
  position: relative;
  > .container {
    @include lg-down {
      max-width: 100%; } }
  .panel-list {
    .icon {
      width: 66px;
      text-align: center;
      display: inline-block;
      @include md-down {
        width: 48px; }
      @include sm-down {
        width: 42px; }
      img {
        max-width: 100%; } }
    .title {
      font-size: .9rem;
      font-weight: 600;
      color: $primary; }
    p {
      @include sm-down {
        font-size: .9rem; } } } }

.latest-courses-section {
  background: $gray-200;
  .slideArea {
    overflow-x: auto;
    overflow-y: hidden;
    ul {
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 1rem;
      white-space: nowrap;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      position: relative;
      display: flex;
      @include md-down {
        padding: 0 0 0 1rem; }
      @include sm-down {
        padding: 0 0 0 .8rem; } }
    .scroll-item {
      flex: 0 0 22%;
      max-width: 22%;
      padding-right: 1.5rem;
      margin-bottom: 2rem;
      @include xl-up {
        flex: 0 0 18%;
        max-width: 18%; }
      @include md-down {
        flex: 0 0 36%;
        max-width: 36%;
        padding-right: 1rem;
        margin-bottom: 1rem; }
      @include sm-down {
        flex: 0 0 42%;
        max-width: 42%;
        padding-right: .8rem; } } } }

.promote-teacher {
  .promote-mate {
    color: $gray-400;
    font-size: .9rem; }
  .tags {
    .tag {
      display: inline-block;
      &:not(:last-child) {
        margin-right: .5rem; } } }
  .date {
    @extend %font-Roboto;
    letter-spacing: 2px; }
  .title {
    color: $white;
    font-size: 2rem; } }

.top-five {
  background: url('../images/top5-bg.jpg') no-repeat;
  background-size: cover;
  overflow: hidden;
  .title-svg {
    width: 180px;
    @include sm-down {
      width: 90px; } }
  .card-topfive {
    margin: 0 1rem;
    @include sm-down {
      margin: 0 .5rem; } }
  .card-slider-wrapper {
    padding: 30px 0 60px 0;
    margin-right: -20vw;
    @include sm-down {
      margin-right: 0; }
    .slick-arrow {
      border: 1px solid $white;
      width: 42px;
      height: 42px;
      &::after {
        width: 16px;
        height: 16px;
        top: 12px;
        left: 15px; }
      &.slick-prev {
        top: auto;
        bottom: 0px; }
      &.slick-next {
        top: auto;
        bottom: 0px;
        right: calc( 20vw + 10px );
        &::after {
          left: auto;
          right: 15px; } } }
    .slick-dots {
      margin-left: -10vw;
      @include sm-down {
        margin-left: 0; }
      > li {
        &.slick-active {
          > button {
            background: $primary; } }
        > button {
          background: $white; } } } } }

.slider-teacher {
  padding-top: 1rem;
  padding-bottom: 3rem;
  .card-teacher {
    margin: 0 1rem 1rem 1rem;
    @include sm-down {
      margin: 0 .5rem 1rem .5rem; } }
  .slick-dots {
    > li {
      &.slick-active {
        > button {
          background: $primary; } }
      > button {
        background: $gray-300; } } } }

.selected-teachers {
  > .container {
    @include md-down {
      max-width: 100%; } } }

.home-features {
  .features-item {
    text-align: center;
    @include sm-down {
      display: flex;
      text-align: left;
      justify-content: flex-start; }
    .icon-item {
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background: $danger;
      padding: 1rem;
      @include sm-down {
        width: 50px;
        height: 50px;
        flex: 0 0 50px;
        max-width: 50px;
        padding: .6rem;
        margin-right: 1rem; } } }
  .title {
    font-size: 1.2rem; }
  p {
    color: $gray-500;
    font-size: .85rem; } }

.home-sharing {
  .card-share {
    margin: 1rem 0; } }
