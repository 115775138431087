.bg-black {
  background-color: $black; }
.card-link {
  &:hover,&:focus {
    text-decoration: none; } }
.btn {
  &.btn-facebook {
    background-color: #4267B2;
    border-color: #4267B2;
    color: $white;
    &:hover,&:focus {
      background-color: darken(#4267B2, 5%);
      border-color: darken(#4267B2, 5%); } } }

.img-ratio-1by1 {
  @extend %img-ratio;
  padding-bottom: 100%; }
.img-ratio-2by1 {
  @extend %img-ratio;
  padding-bottom: 50%; }
.img-ratio-4by3 {
  @extend %img-ratio;
  padding-bottom: 75%; }
.img-ratio-4by1 {
  @extend %img-ratio;
  padding-bottom: 25%; }
.img-ratio-16by9 {
  @extend %img-ratio;
  padding-bottom: 56.25%; }

.font-size-lg {
  font-size: 1.35em; }
.font-size-xl {
  font-size: 1.6em; }

.letter-spaceing-1 {
  letter-spacing: 1px; }
.letter-spaceing-2 {
  letter-spacing: 2px; }
.letter-spaceing-3 {
  letter-spacing: 3px; }

.form-control, .custom-select {
  border-color: $gray-500;
  &:focus {
    border-color: $gray-900;
    outline: 0;
    box-shadow: 0 0 3px 1px rgba(0,0,0,.2); }
  &.dark-control {
    background: transparent;
    color: $gray-200;
    border-color: $white;
    &:foucs {
      color: $white; } } }
.form-control-plaintext {
  color: $gray-800;
  background-color: $gray-100;
  padding: 0.375rem 15px; }

.modal {
  .modal-header {
    .close {
      position: absolute;
      top: 1rem;
      right: 1rem; } } }

// Customize Radiobox
.custom-control {
  padding-left: 2.25rem; }
.custom-control-label {
  padding-top: .25rem;
  padding-bottom: .25rem;
  cursor: pointer;
  &::before {
    width: 24px;
    height: 24px;
    border-color: $gray-500;
    background: $white;
    left: -2.25rem; } }
.custom-control-input {
  &:checked ~ .custom-control-label::before {
    border-color: $gray-800;
    background-color: $gray-800; }
  &:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 rgba(0,0,0,0); }
  &:focus:not(:checked) ~ .custom-control-label::before {
    border-color: $gray-500; } }
.custom-radio {
  .custom-control-input {
    &:checked ~ .custom-control-label::after {
      background: url('../images/icon-checked.svg') no-repeat center center;
      width: 24px;
      height: 24px;
      left: -2.25rem; } } }

.icon {
  &.size-18 {
    width: 18px; }
  &.size-20 {
    width: 20px; } }



.paddingY {
  padding: 3rem 0; }

.pagination {
  .page-link {
    width: 38px;
    height: 38px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-color: transparent;
    color: $gray-600;
    &:hover {
      color: $primary;
      background: $white;
      border-color: transparent;
      box-shadow: 0 0 2px rgba(0,0,0,.5); } }
  .page-item {
    padding: 0 .25rem;
    &.disabled {
      cursor: not-allowed;
      .page-link {
        background: $gray-300;
        color: $gray-400;
        border-color: $gray-300; } }

    &.active {
      .page-link {
        color: $white;
        background: $primary;
        &:hover {
          color: $white;
          border-color: $primary; } } } } }
.cover-img {
  &.posa {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover; } }

.star-wrapper {
  letter-spacing: -2px;
  &.star-sm {
    font-size: .85rem; } }


.chapter {
  line-height: 1.6;
  font-size: 1.1rem;
  // font-weight: 300
  @include sm-down {
    line-height: 1.5;
    font-size: 1rem; } }


blockquote {
  border-left: 8px solid $gray-300;
  margin: 1.8rem 10px;
  padding: 0.85rem 1.5rem 0.85rem;
  quotes: "“" "”" "‘" "’";
  font-style: italic;
  &:before {
    font-style: normal;
    color: $gray-100;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em; }
  &:after {
    font-style: normal;
    color: $gray-100;
    content: close-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -1em; }
  p {
 } }    // display: inline

.search-input-row {
  display: flex;
  align-items: center;
  justify-content: center;
  .btn-search {
    flex: 0 0 80px;
    max-width: 80px; } }
.result-row {
  .keywords {
    font-size: 1rem; } }
