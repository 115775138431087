.video-top-container {
  background: $black;
  padding: 1rem 0 2rem;
  .video-meta {
    margin-top: 1rem;
    .meta-info {
      max-width: 70%;
      flex: 0 0 70%;
      @include lg-down {
        max-width: 60%;
        flex: 0 0 60%; }
      @include sm-down {
        max-width: 100%;
        flex: 0 0 100%; }
      > .row {
        @include md-down {
          margin-left: -8px;
          margin-right: -8px; }
        > [class*="col-"] {
          @include md-down {
            padding-left: 8px;
            padding-right: 8px; } } }
      .meta {
        @include md-down {
          font-size: .9rem; }
        &.tags {
          margin-top: .8rem; }
        .fas, far {
          line-height: 1.6; } }
      .tags-wrapper {
        @include md-up {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block; } }
      .star-wrapper {
        letter-spacing: -1px;
        @include lg-down {
          font-size: .9rem; } } }
    .purchase-container {
      max-width: 30%;
      flex: 0 0 30%;
      height: 100%;
      overflow: auto;
      @extend %font-Roboto;
      font-weight: 400;
      @include lg-down {
        max-width: 40%;
        flex: 0 0 40%; }
      @include sm-down {
        max-width: 100%;
        flex: 0 0 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background: $white;
        height: 50px;
        z-index: 999; }
      .prcie-wrapper {
        @include sm-down {
          text-align: center; } }
      .countdown {
        color: $yellow;
        @include sm-down {
          position: fixed;
          bottom: 54px;
          left: 50%;
          z-index: 667;
          transform: translateX(-50%);
          background: $yellow;
          border-radius: 50px;
          padding: .25rem 1rem;
          color: $gray-800; } }
      .crossline-price {
        font-weight: 300;
        color: $gray-200;
        font-size: 1rem;
        @include sm-down {
          color: $gray-800;
          font-size: .9rem; }
        .currency {
          font-weight: 300; } }


      .early-bird {
        line-height: 1;
        .product-price {
          @include sm-down {
            font-size: 1.45rem; } } }
      .currency {
        font-size: .9rem; }
      .product-price {
        font-size: 2rem;
        @include sm-down {
          font-size: 1.6rem; } }
      .btn-purchase {
        @include sm-down {
          height:  50px;
          font-size: 1.2rem;
          padding: .7rem .5rem; } } } } }

header {
  &.video-header {
    .title {
      font-size: 1.8rem;
      font-weight: 300;
      margin-bottom: 1.2rem;
      @include md-down {
        font-size: 1.5rem;
        margin-bottom: 1rem; }
      @include sm-down {
        font-size: 1.2rem;
        margin-bottom: .65rem; } }
    .badge {
      border-radius: 50px;
      padding: .5rem 1rem; } } }
.header-video-container {
  flex-wrap: wrap;
  height: 478px;
  @include lg-down {
    height: 406px; }
  @include md-down {
    height: 390px; }
  @include sm-down {
    height: auto; }
  .video-embed {
    max-width: 70%;
    flex: 0 0 70%;
    @include sm-down {
      max-width: 100%;
      flex: 0 0 100%; }
    .video-cover {
      position: relative;
      .buy-video {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(#000000,.6);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 1.5rem;
        font-weight: 300; }
      .icon-buy {
        width: 90px; } } }
  .chapter-lists {
    max-width: 30%;
    flex: 0 0 30%;
    height: 100%;
    overflow: auto;
    @include sm-down {
      display: none; }
    &.open-chapter {
      position: fixed;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1098;
      padding: 0 .5rem;
      background: darken($gray-800, 10%);
      .chapter-mmenu-header {
        display: flex;
        align-items: {} }
      .lists-container {
        height: calc(100% - 60px);
        overflow: auto; } }
    .chapter-mmenu-header {
      height: 60px;
      position: relative;
      padding: .25rem 100px .25rem 1rem;
      box-shadow: 0 2px 10px rgba(0,0,0,.3);
      margin-left: -.5rem;
      margin-right: -.5rem;
      background: lighten($gray-800, 5%);
      display: none;
      align-items: center;
      .cousrse-title {
        font-size: .95rem;
        @extend %word-break-2; }
      .close-menu {
        position: absolute;
        top: 0;
        right: 0;
        width: 80px;
        text-align: center;
        height: 100%;
        border-left: $gray-800 1px solid;
        padding: 1rem;
        cursor: pointer;
        &:hover {
          background: lighten($gray-800, 15%); } } }
    .lists-container {
      @include sm-down {
        height: calc(100% - 60px);
        overflow: auto; } }
    .item {
      background: darken($gray-800, 10%);
      cursor: pointer;
      padding: .85rem 1rem;
      border-bottom: 1px solid lighten($gray-800, 5%);
      color: $gray-500;
      &:hover {
        background: $gray-800;
        color: $white; }
      &.active {
        background: $gray-800;
        color: $white;
        .icon {
          color: $primary; } }
      .item-title {
        font-size: 16px;
        font-weight: normal;
        flex: 1 1 auto;
        margin-bottom: 0;
        @extend %word-break-1; }
      .icon {
        max-width: 30px;
        flex: 0 0 30px;
        font-size: 1.3rem;
        line-height: 1;
        color: $white; }
      .time {
        max-width: 46px;
        flex: 0 0 46px;
        font-size: .85rem;
        text-align: right;
        @include md-down {
          display: none; }
        @include sm-down {
          display: block; } } } } }

.video-main-content {
  &.bg-light {
    color: $gray-800; }
  .video-tabs-panel {
    background: $white;
    box-shadow: 50px 50px 100px rgba(0,0,0,.15);
    .nav-tabs {
      border: 0;
      .nav-link {
        border: 0;
        padding: 1.2rem 0;
        color: $gray-800;
        @include sm-down {
          font-size: .85rem; }
        &:after {
          content: '';
          display: block;
          width: 100%;
          max-width: 0px;
          height: 3px;
          border-radius: 5px;
          background-color: $danger;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          transition: .2s width cubic-bezier(0.57, 1.83, 1, 1), .2s max-width cubic-bezier(0.57, 1.83, 1, 1), .2s bottom cubic-bezier(0.57, 1.83, 1, 1); }
        &:hover {
          &:after {
            width: 60%;
            max-width: 100px; } }
        &.active {
          color: $danger;
          font-weight: 600;
          &:after {
            width: 100%;
            max-width: 100px; } } } } }
  .tab-pane {
    padding: 2rem 0; } }

.teacher-meta {
  .teacher-name {
    font-size: 1rem;
    margin-bottom: 1rem; } }

.respond-score {
  .score {
    font-size: 2.5rem;
    @extend %font-Roboto; }
  .respond-star {
    p {
      font-size: .9rem;
      color: $gray-600; } } }

.comment-list-item {
  box-shadow: 0 0 2px rgba(0,0,0,.2);
  border-radius: 2px;
  padding: 1rem;
  background: $white;
  @include sm-down {
    padding: .6rem; }
  &:not(:last-child) {
    margin-bottom: 1rem;
    @include sm-down {
      margin-bottom: .5rem; } }
  .comment-image {
    font-size: .85rem;
    text-align: center;
    flex: 0 0 120px;
    max-width: 120px;
    @include sm-down {
      flex: 0 0 50px;
      max-width: 50px; }
    .thumbnail {
      width: 66px;
      margin: 0 auto .54rem auto;
      @include sm-down {
        width: 50px; } } }
  .user-info {
    p {
      color: $gray-800;
      font-size: .9rem; }
    .date {
      color: $gray-500;
      font-size: .85rem; } }

  .comment-content {
    padding: 0 0 0 1rem;
    flex: 1 0 calc( 100% - 120px);
    max-width: calc( 100% - 120px);
    @include sm-down {
      flex: 1 0 calc( 100% - 50px);
      max-width: calc( 100% - 50px);
      padding: 0 0 0 .5rem; }
    .title {
      font-size: 1rem;
      font-weight: 600; }
    .commment {
      font-size: .9rem; } } }

.question-list-item {
  &:not(:last-child) {
    margin-bottom: 1rem;
    @include sm-down {
      margin-bottom: .5rem; } }
  .question-image {
    font-size: .85rem;
    text-align: center;
    flex: 0 0 66px;
    max-width: 66px;
    @include sm-down {
      flex: 0 0 36px;
      max-width: 36px; }
    .thumbnail {
      width: 66px;
      margin: 0 auto .54rem auto;
      @include sm-down {
        width: 36px; } } }
  .user-info {
    p {
      color: $gray-800;
      font-size: .9rem; }
    .date {
      color: $gray-500;
      font-size: .85rem; } }
  .question-content {
    padding: 0 0 0 1rem;
    flex: 1 0 calc( 100% - 66px);
    max-width: calc( 100% - 66px);
    @include sm-down {
      flex: 1 0 calc( 100% - 36px);
      max-width: calc( 100% - 36px);
      padding: 0 0 0 .5rem; }
    .title {
      font-size: 1rem;
      font-weight: 600; }
    .commment {
      font-size: .9rem; } }
  .reply-it {
    cursor: pointer; } }

.question-list-subitem {
  background-color: darken($gray-100, 5%);
  padding: 10px;
  &:first-child {
    margin-top: 1rem; }
  & + &.question-list-subitem {
    border-top: 1px solid $gray-300; }
  &.reply-message {
    display: flex; }
  .user-info {
    p {
      color: $gray-800;
      font-size: .9rem; }
    .date {
      color: $gray-500;
      font-size: .85rem; } }
  .question-image {
    font-size: .85rem;
    text-align: center;
    flex: 0 0 60px;
    max-width: 60px;
    @include sm-down {
      flex: 0 0 36px;
      max-width: 36px; }
    .thumbnail {
      width: 60px;
      margin: 0 auto .54rem auto;
      @include sm-down {
        width: 36px; } } }
  .question-content {
    padding: 0 0 0 1rem;
    flex: 1 0 calc( 100% - 60px);
    max-width: calc( 100% - 60px);
    @include sm-down {
      flex: 1 0 calc( 100% - 36px);
      max-width: calc( 100% - 36px);
      padding: 0 0 0 .5rem; }
    .title {
      font-size: 1rem;
      font-weight: 600; }
    .commment {
      font-size: .9rem; } } }

.process-bar-container {
  color: $gray-400;
  font-size: .8rem;
  @include sm-down {
    margin-top: .75rem; }
  .bar {
    border-radius: 50px;
    background: $gray-800;
    height: 8px;
    position: relative;
    margin-top: .25rem;
    .percent {
      background: #ff5722;
      background: linear-gradient(to left, #ff5722 0%, $primary 100%);
      height: 8px;
      border-radius: 50px;
      position: absolute;
      top: 0;
      left: 0; } } }
