.breadcrumb {
  background: transparent;
  font-size: .95rem;
  padding-left: 0;
  padding-right: 0;
  .breadcrumb-item {
    @extend %word-break-1;
    max-width: 180px;
    display: block;
    &.active {
      color: $gray-400; } }
  a {
    color: $white; } }

.bg-light {
  background: $gray-200 !important; }
