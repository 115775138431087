body {
  position: relative;
  background-color: $gray-900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Noto Sans TC', Roboto, 'Microsoft JhengHei', 'Helvetica Neue', Arial, sans-serif;
  color: $white;
  &.modal-open {
    padding-right: 0px !important;
    .mm-page {
      position: initial; } }
  &.modalChapter-open {
    overflow: hidden; } }

.row {
  @include sm-down {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  > *[class^='col'] {
    @include sm-down {
      padding-left: 0.5rem;
      padding-right: 0.5rem; } } }

.container, *[class^='container'] {
  @include sm-down {
    padding-left: 0.5rem;
    padding-right: 0.5rem; } }
footer {
  &.page-footer {
    font-size: .95rem;
    color: $gray-400;
    a {
      color: $white; }
    .title {
      font-size: 1rem;
      font-weight: 400;
      color: $white;
      margin-bottom: 1.5rem;
      @extend %font-Roboto;
      @include md-down {
        margin-bottom: .6rem; } }
    .footer-above {
      padding: 2rem 0;
      @include sm-down {
        padding: 1rem .5rem; }
      ul {
        li {
          margin-bottom: .6rem;
          line-height: 1.6; } }
      .icon {
        width: 36px; }
      .logo {
        width: 146px; } }
    .social-share {
      .icon {
        width: 24px;
        display: inline-block; } }

    .copyright {
      border-top: 1px solid $gray-800;
      padding-top: 1rem;
      font-size: .85rem;
      color: $gray-600; } } }
