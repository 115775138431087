section {
  &.paddingY {
    padding: 3rem 0; }
  &.theme-light {
    color: $gray-600; }
  &.bg-black {
    background: $black; } }

.section-title {
  position: relative;
  padding-top: 1rem;
  &.danger {
    &:after {
      background: $danger; } }
  &:after {
    content: '';
    display: block;
    width: 46px;
    height: 3px;
    border-radius: 10px;
    background: $primary;
    position: absolute;
    top: 0;
    left: calc(50% - 23px); }
  &.no-border {
    &:after {
      display: none; } }
  &.text-left {
    &:after {
      left: 0; } }
  .title {
    font-size: 2.4rem;
    @include md-down {
      font-size: 2rem; } }
  .title2 {
    font-size: 2rem;
    @include md-down {
      font-size: 1.3rem; } }
  .en-title {
    font-size: .9rem;
    @extend %font-Roboto;
    @include md-down {
      font-size: .85rem; } }
  .sub-title {
    font-size: 1rem; } }

.static-content {
  img {
    max-width: 100%; }
  p {
    line-height: 1.3;
    font-size: 1rem;
    margin-bottom: .85rem; } }

.register-section {
  .forget-password {
    font-size: .9rem; }
  .also {
    font-size: .8rem;
    color: $gray-600;
    overflow: hidden;
    span {
      display: inline-block;
      position: relative;
      &:after, &:before {
        content: '';
        display: block;
        width: 1200px;
        height: 1px;
        background-color: $gray-600;
        position: absolute;
        top: 50%; }
      &:before {
        left: calc(100% + 1rem); }
      &:after {
        right: calc(100% + 1rem); } } }
  .cover-img {
    @include sm-down {
      opacity: .6; } }
  .register-field {
    padding: 3rem 0 5rem;
    @include sm-down {
      background: $gray-800;
      padding: 2rem 1.5rem;
      margin-top: -6rem;
      margin-bottom: 3rem;
      box-shadow: 0 0 15px 2px rgba(0,0,0,.5); } } }

.member-menu {
  .avatar {
    width:  168px; }
  .user-name {
    font-size: 1.1rem;
    font-weight: 600; }
  .member-navi {
    .menu-link {
      color: $gray-400;
      padding: .85rem 1rem;
      &:hover {
        color: $gray-800;
        text-decoration: none; }
      &.active {
        color: $gray-900;
        font-weight: 600; }
      > i {
        margin-right: .5rem; } } } }

.order-header {
  font-size: .9rem; }
.order-detail {
  font-size: .9rem; }
.course-list-item {
  .course-item {
    display: flex;
    flex-wrap: wrap; }
  .item-thumbnail {
    flex: 0 0 20%;
    max-width: 20%;
    @include md-down {
      flex: 0 0 25%;
      max-width: 25%; } }
  .item-body {
    flex: 0 0 60%;
    max-width: 60%;
    font-size: .95rem;
    @include md-down {
      flex: 0 0 75%;
      max-width: 75%; }
    .title {
      font-size: 1.1rem;
      @include md-down {
        font-size: 1rem; } } }
  .item-price {
    flex: 0 0 20%;
    max-width: 20%;
    text-align: right;
    @include md-down {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 2px solid $gray-200;
      display: flex;
      justify-content: space-between;
      align-items: center; }
    .pricing {
      @extend %font-Roboto;
      font-size: 28px;
      @include sm-down {
        font-size: 20px; } } } }

.my-course-list {
  background: $white;
  color: $gray-800;
  .course-card-item {
    display: flex;
    flex-wrap: wrap; }
  .course-thumbnail {
    flex: 0 0 30%;
    max-width: 30%;
    @include md-down {
      flex: 0 0 35%;
      max-width: 35%; }
    @include sm-down {
      flex: 0 0 100%;
      max-width: 100%; } }
  .course-body {
    flex: 0 0 70%;
    max-width: 70%;
    font-size: .95rem;
    @include md-down {
      flex: 0 0 65%;
      max-width: 65%; }
    @include sm-down {
      flex: 0 0 100%;
      max-width: 100%; }
    .course-desc {
      font-size: .95rem;
      font-weight: 300;
      @include sm-down {
        font-size: .9rem; } }
    .title {
      font-size: 1.1rem;
      font-weight: 600;
      @include sm-down {
        font-size: 1rem; } }
    .pricing {
      @extend %font-Roboto;
      font-size: 28px;
      @include sm-down {
        font-size: 20px; } } } }

.policy-content {
  h4 {
    font-weight: 500;
    font-size: 1.4rem;
    @include sm-down {
      font-size: 1.2rem; } }
  hr {
    border-style: dashed; } }
