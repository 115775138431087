.card {
  &.card-course {
    color: $gray-500;
    font-size: .9rem;
    line-height: 1.2;
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(0,0,0, .15);
    @include sm-down {
      margin-top: 25px; }
    &:hover,&:focus {
      .card-title {
        color: $primary; }
      .card-body {
        color: $gray-900; }
      .thumbnail {
        img {
          transform: scale(1.2); } } }
    .card-body {
      padding-top: 2rem;
      border-radius: 0 0 6px 6px;
      @include sm-down {
        padding: .6rem; } }
    .card-img {
      position: relative;
      border-radius: 6px 6px 0 0;
      .thumbnail {
        overflow: hidden;
        > img {
          border-radius: 6px 6px 0 0;
          transition: .2s transform ease-out; } }
      .avatar {
        width: 60px;
        position: absolute;
        bottom: -22px;
        right: 1rem;
        @include sm-down {
          width: 50px;
          bottom: auto;
          top: -25px;
          left: 1rem;
          right: auto; }
        img {
          width: 100%;
          box-shadow: 5px 5px 15px rgba(0,0,0,.15); } } }

    .card-title {
      color: $gray-900;
      font-size: 1.05rem;
      line-height: 1.3;
      margin-bottom: 5px;
      @extend %word-break-2;
      height: 42px;
      @include md-down {
        font-size: .95rem; } }
    .card-desc {
      @extend %word-break-2; }
    .price {
      font-size: 1rem;
      @extend %font-Roboto;
      .pricing {
        font-size: 22px; }
      .currency {
        font-size: .72rem; } } }

  //
  &.card-topfive {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    &:hover,&:focus {
      .card-image {
        > img {
          transform: scale(1.2); } } }
    .card-content {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: $white;
      padding: 1rem; }
    .card-image {
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background-image: radial-gradient(rgba(#000000,0), rgba(#000000,.4));
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0; }
      > img {
        transition: .2s transform ease-out; } }
    .card-title {
      @extend %word-break-2;
      height: 38px;
      font-size: 1rem;
      @include sm-down {
        font-size: .9rem;
        height: 34px; } }
    .label-award {
      position: absolute;
      top: 0;
      left: 0;
      width: 80px;
      height: 80px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 80px 80px 0 0;
        border-color: $danger transparent transparent transparent;
        z-index: 2;
        @include sm-down {
          border-width: 50px 50px 0 0; } }
      span {
        position: absolute;
        color: $white;
        font-size: 1.5rem;
        @extend %font-Roboto;
        z-index: 20;
        display: block;
        top: 10%;
        left: 20%;
        @include sm-down {
          top: 10%;
          left: 12%;
          font-size: 1.2rem; } } } }
  &.card-teacher {
    color: $gray-600;
    box-shadow: 0px 0px 10px rgba(0,0,0, .15);
    overflow: hidden;
    border-radius: 6px;
    .card-img {
      overflow: hidden;
      &:hover,&:focus {
        img {
          transform: scale(1.2); } }
      img {
        transition: .2s transform ease-out; } }
    .icon {
      width: 16px;
      display: inline-block;
      margin-right: .2rem; }
    .mate {
      font-size: .9rem;
      padding: 0 0 .8rem 0;
      a {
        color: $gray-600; } }
    .card-body {
      padding: .8rem 1rem;
      @include sm-down {
        padding: .6rem; } }
    .card-title {
      font-size: 1rem;
      color: $gray-900;
      @extend %word-break-2; } }

  &.card-share {
    color: $gray-800;
    box-shadow: 0 0 15px rgba(0,0,0,.1);
    border-radius: 6px;
    .card-body {
      @include sm-down {
        padding: .6rem; } }
    .name {
      font-size: 1rem;
      color: $gray-900;
      font-weight: 700;
      @include sm-down {
        font-size: .9rem; } }
    .description {
      font-size: .95rem;
      @include sm-down {
        font-size: .9rem; } }
    .avatar {
      width: 60px;
      @include sm-down {
        width: 45px; } } }
  &.card-respond {
    box-shadow: 0 0 2px rgba(0,0,0,.2);
    border-radius: 2px;
    hr {
      border-color: $gray-200; }
    .card-title {
      font-size: 1.3rem;
      @include sm-down {
        font-size: 1.1rem; } } }

  &.card-panel {
    background: $white;
    color: $gray-800;
    &.box-shadow {
      box-shadow: 0 0 12px 1px rgba(0,0,0,.1); }
    hr {
      border-width: 2px;
      border-color: $gray-200; }
    .card-header {
      background: $white;
      border: 0;
      @include lg-up {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem; }
      .icon {
        width: 30px;
        display: inline-block; }
      &.border-b {
        position: relative;
        &:after {
          content: '';
          display: block;
          width: calc(100% - 2.5rem);
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 1.25rem;
          background: $gray-200; } } }
    .card-title {
      font-size: 1.5rem; }
    .card-body {
      @include lg-up {
        padding-top: 2rem;
        padding-bottom: 2rem; } }
    .btn-sm {
      .icon {
        width: 16px;
        display: inline-block; } }
    .carts {
      .cart-item {
        &:not(:last-child) {
          margin-bottom: 1rem; }
        &.has-btn {
          .cart-info {
            max-width: calc( 100% - 170px);
            flex: 0 0 calc( 100% - 170px);
            padding-left: .5rem;
            padding-right: .5rem;
            @include md-down {
              max-width: calc( 100% - 100px);
              flex: 0 0 calc( 100% - 100px); }
            @include sm-down {
              max-width: calc( 100% - 90px);
              flex: 0 0 calc( 100% - 90px); } }
          .cart-course {
            max-width: 120px;
            flex: 0 0 120px;
            @include md-down {
              max-width: 50px;
              flex: 0 0 50px; }
            @include sm-down {
              max-width: 40px;
              flex: 0 0 40px; } } } }

      .cart-thumbnail {
        max-width: 50px;
        flex: 0 0 50px; }
      .cart-info {
        max-width: calc( 100% - 50px);
        flex: 0 0 calc( 100% - 50px);
        padding-left: 1rem;
        .title {
          font-weight: 600;
          color: $gray-900;
          line-height: 1.3;
          margin-bottom: .25rem; } } }
    .currency {
      font-size: .8rem; } } }
.social-share-aside {
  border-radius: 5px;
  @include lg-up {
    box-shadow: 0 0 10px rgba(0,0,0,.15);
    border-radius: 5px;
    padding: 1rem;
    background: $white; }
  @include lg-down {
    padding: .65rem; }
  .icon {
    width: 24px;
    display: inline-block; } }
