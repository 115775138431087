.navbar {
  display: flex;
  position: relative;
  .navbar-toggler {
    position: absolute;
    top: 50%;
    left: 0rem;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: $white;
    border: 0; }
  .navbar-nav {
    .nav-item {
      position: relative;
      &:hover {
        .nav-link {
          &:after {
            width: 24px; } } }
      &.active {
        .nav-link {
          color: $primary;
          font-weight: 600;
          &:after {
            width: 24px;
            background-color: $primary; } } } }
    .nav-link {
      color: $white;
      &:after {
        content: '';
        display: block;
        width: 0;
        height: 3px;
        border-radius: 5px;
        background-color: $white;
        position: absolute;
        top: 0;
        right: .5rem;
        transition: .2s width ease; } } }
  .navbar-collapse {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); }
  .navbar-brand {
    width: 126px;
    @include md-down {
      width: 96px; }
    @include sm-down {
      width: 86px; } }
  .navbar-search {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); } }

.share-item {
  .share-link {
    display: inline-block;
    &:hover, &:focus {
      .icon {
        .hover {
          display: inline-block; }
        .normal {
          display: none; } } }
    .icon {
      .hover {
        display: none; } } } }

