.about-page-header {
  position: relative;
  height: calc(100vh - 96px);
  min-height: 560px;
  .about-header-container {
    height: calc( 100% - 140px ); }
  .cover-img {
    z-index: -1; } }
.about-section-cover {
  position: relative;
  height: calc(100vh - 96px);
  min-height: 560px;
  .cover-img {
    z-index: -1; } }
.about-section {
  .content {
    &.chapter {
      padding: 3rem 0;
      line-height: 1.78; } } }
